import React, { useState, useEffect } from 'react';
import { FaEllipsisV, FaChevronLeft, FaChevronRight, FaUser, FaRegEdit, FaTrashAlt, FaPlus, FaSignOutAlt, FaEllipsisH } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import './NavAIComp.css';
import logo from '../../assets/postcare.png';

const NavbarAIComp = ({ chatSessions, onSessionSelect, handleEdit, handleDelete, onNewChat, onLogoutClick }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activeSession, setActiveSession] = useState(null); // Active session for showing the button
  const [menuVisible, setMenuVisible] = useState(null); // Store ID for visible menu
  const location = useLocation();
  const { userEmail } = location.state || {};

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    setIsOpen(window.innerWidth > 768); 
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleNavbar = () => setIsOpen(!isOpen);
  const toggleMenu = (sessionId) => setMenuVisible(menuVisible === sessionId ? null : sessionId);

  return (
    <>
      <div className={`h-screen fixed top-0 left-0 transition-transform bg-white ${isOpen ? 'w-64' : 'w-16'} ${isMobile ? 'transform -translate-x-full' : ''} ${isOpen ? 'translate-x-0' : '-translate-x-full'}`} style={{ backgroundColor: 'white' }}>
        <div className="flex items-center justify-between px-2 py-3">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className={`h-12 cursor-pointer ${isOpen ? '' : 'hidden'}`} />
            {isOpen && (
              <button className="text-gray-600 p-2 bg-transparent hover:bg-transparent focus:outline-none" onClick={toggleNavbar}>
                <FaChevronLeft size={24} />
              </button>
            )}
          </div>
        </div>

        <hr className="w-full border-gray-200 my-2" />

        {isOpen && (
          <div className="chat-history-container flex flex-col space-y-2 mt-4 px-2">
            <button className="bg-blue-500 text-white px-4 py-2 rounded-lg mb-2 shadow-lg hover:bg-blue-600" onClick={onNewChat}>
              <FaPlus className="inline-block mr-2" /> New Chat
            </button>
            <hr className="w-full border-gray-200 my-2" />
            <ul>
              {chatSessions.map((session) => (
                <li key={session.id} className="chat-item-container flex justify-between items-center p-2 hover:bg-gray-100">
                  <span onClick={() => onSessionSelect(session.id)} className="text-gray-700 cursor-pointer">
                    {session.id}
                  </span>
                  
                  <div className="relative">
                    {/* Three dot button */}
                    <FaEllipsisV
                      className={`three-dot-button ${activeSession === session.id ? 'active' : ''}`}
                      onClick={() => toggleMenu(session.id)}
                    />

                    {/* Edit/Delete Options Box */}
                    {menuVisible === session.id && (
                      <div className="chat-options">
                        <button onClick={() => handleEdit(session.id)}>
                          <FaRegEdit className="mr-2" /> Edit
                        </button>
                        <button onClick={() => handleDelete(session.id)}>
                          <FaTrashAlt className="mr-2" /> Delete
                        </button>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        {isOpen && (
          <div className="user-info-container ">
            <div className="flex flex-col w-full mt-auto mb-4" style={{ marginTop: '290px', zindex: '999' }}>
              <hr className="w-60 border-gray-200 my-2" />
              <div className="flex w-full items-center mb-4">
                <div className="w-1/3 flex items-center justify-center" style={{ marginLeft: '-20px', fontSize: '13px' }}>
                  <FaUser className="text-gray-500 h-8 w-8" style={{ marginBottom: '4px' }} />
                </div>
                <div className="w-2/3 text-left">
                  <div className="text-gray-700 font-400">{userEmail}</div>
                  <div className="text-black text-sm font-bold" style={{ width: '80px', padding: '1px 5px', border: '2px solid purple', boxShadow: '0 0 1px 2px blue' }}>
                    Free Trial
                  </div>
                </div>
              </div>
              <hr className="w-60 border-gray-200 my-2" />
              <button className="w-full px-4 py-2 flex items-center justify-start text-gray-700 hover:text-gray-900" onClick={onLogoutClick}>
                <FaSignOutAlt className="mr-2" />
                Logout
              </button>
            </div>
          </div>
        )}
      </div>

      {!isOpen && (
        <div className="fixed top-0 left-0 h-screen w-10 bg-blue-500 flex items-center justify-center cursor-pointer" onClick={toggleNavbar}>
          <FaChevronRight size={24} className="text-white" style={{ marginTop: '-530px' }} />
        </div>
      )}
    </>
  );
};

export default NavbarAIComp;
