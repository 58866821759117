// AIDashboard.js
import React, { useState , useEffect} from "react";
import AICompanion from "./aicompanion";
import NavbarAIComp from "./NavAIcomp"; // Ensure correct import path
import { FaStar } from "react-icons/fa"; // Import FaStar
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";


const AIDashboard = () => {
    const [chatSessions, setChatSessions] = useState([]);  // Array of chat sessions
    const [activeSession, setActiveSession] = useState(null);  // Currently active chat
    const [resetAICompanion, setResetAICompanion] = useState(false); // Track reset for AICompanion
    const [isLogoutClicked, setIsLogoutClicked] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [currentValue, setCurrentValue] = useState(0);
    const [hoverValue, setHoverValue] = useState(undefined);
    const [isThankYouVisible, setIsThankYouVisible] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();


    const { userEmail } = location.state || {};

    const handleLogoutClick = () => {
      setIsLogoutClicked(true);
    };

    const handleLogout = async () => {
        try {
          await axios.get('/auth/logout');
          navigate('/'); // Redirect to homepage
          } catch {
            navigate('/')
        // } catch (error) {
        //   console.error('Logout failed', error);
        }
      };


const handleSubmitFeedback = () => {
  const feedbackData = {
        email: localStorage.getItem(userEmail), // Replace with actual email if needed
        stars: currentValue,
        comment: feedback,
      };
  
      localStorage.setItem('feedback', JSON.stringify(feedbackData));
      console.log('Feedback saved:', feedbackData);
  
    // Handle feedback submission logic
    setIsThankYouVisible(true);
    setTimeout(() => {
          handleLogout(); // Trigger logout after thank you message
        }, 2000); // After 3 seconds, log out
      };

    useEffect(() => {
        if (chatSessions.length === 0) {
          // Create initial chat session on page load
          handleNewChat();
        }
      }, []);

      const handleNewChat = () => {
        const newSessionId = `Chat ${chatSessions.length + 1}`; // Unique session identifier
        const newSession = {
          id: newSessionId,
          timestamp: new Date().toISOString(), // Timestamp to track creation time
        };
        setChatSessions([...chatSessions, newSession]); // Add new chat to sessions array
        setActiveSession(newSession); // Set the new session as active
        setResetAICompanion(true);
      };

      const handleSessionChange = (sessionId) => {
        const session = chatSessions.find((s) => s.id === sessionId);
        setActiveSession(session); // Switch to selected chat session
      };

      useEffect(() => {
        if (resetAICompanion) {
          setResetAICompanion(false); // Reset the flag to false after rendering
        }
      }, [resetAICompanion]);
  
    // Function to handle "New Chat" click
    const handleEdit = (sessionId) => {
        const newName = prompt(`Edit Chat ${sessionId}`, `Chat ${sessionId}`);
        if (newName) {
          const updatedSessions = chatSessions.map((session) =>
            session.id === sessionId ? { ...session, id: newName } : session
          );
          setChatSessions(updatedSessions); // Update session name in history
        }
      };
    
      // Function to handle Delete action
      const handleDelete = (sessionId) => {
        const filteredSessions = chatSessions.filter((session) => session.id !== sessionId); // Remove chat session
        setChatSessions(filteredSessions); // Update session list
        if (activeSession && activeSession.id === sessionId) {
          setActiveSession(filteredSessions.length > 0 ? filteredSessions[0] : null); // Switch to the first session if deleted
        }
      };
  
    return (
      <div>
        <NavbarAIComp
          chatSessions={chatSessions} 
          onSessionSelect={handleSessionChange} 
          handleEdit={handleEdit} 
          handleDelete={handleDelete} 
          onNewChat={handleNewChat}
          onLogoutClick={handleLogoutClick}
        />
        {activeSession && !resetAICompanion && (<AICompanion key={activeSession.id} sessionId={activeSession.id} />)}  {/* Render AICompanion */}

        {/* Logout & Feedback Modal */}
        {isLogoutClicked && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"style={{zIndex:'999'}}>
              <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                <h2 className="text-lg font-semibold mb-4">Thank you for using Postcare's AI Companion!</h2>
                <div className="flex mb-4">
                  {Array(5).fill(0).map((_, index) => (
                    <FaStar
                      key={index}
                      size={24}
                      onClick={() => setCurrentValue(index + 1)}
                      onMouseOver={() => setHoverValue(index + 1)}
                      onMouseLeave={() => setHoverValue(undefined)}
                      color={(hoverValue  > index || currentValue) > index ? '#FFD700' : '#C0C0C0'}
                      style={{ marginRight: 10, cursor: 'pointer' }}
                    />
                  ))}
                </div>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded mb-4"
                  rows="4"
                  placeholder="Optional feedback"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                ></textarea>
                {isThankYouVisible && <p className="text-green-500 mb-4">Thank you for your valuable feedback!</p>}

                <div className="flex justify-end space-x-4">
                  <button
                    onClick={handleSubmitFeedback}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                  >
                    Submit
                  </button>
                  <button
                    onClick={() => setIsLogoutClicked(false)}
                    className="text-gray-500 px-4 py-2 rounded hover:text-gray-700"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  };
  
  export default AIDashboard;