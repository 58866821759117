import React, { useState, useEffect, useRef } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import './historysection.css';
import { useLocation } from 'react-router-dom';
import { MdCancel } from "react-icons/md";
import { FaPills, FaDiagnoses, FaHeartbeat } from 'react-icons/fa';
import NavAIComp from './NavAIcomp';
import ResponseFormat from './responseformat';
import axios from 'axios';
import iicon from '../../assets/iicon.png';
import {v4 as uuid} from "uuid"
import io from 'socket.io-client'


const AICompanion = () => {
  const [conversations, setConversations] = useState([]);
  const [currentConversations, setCurrentConversations] = useState([]);
  const [newChatName, setNewChatName] = useState('');
  const [activeChat, setActiveChat] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [showOptions, setShowOptions] = useState(true);
  const [timer, setTimer] = useState(1800);  // 30 minutes in seconds
  const [showDropdown, setShowDropdown] = useState(false);  // Track dropdown state
  // const [isSessionStarted, setIsSessionStarted] = useState(false); // New flag to check session status
  const [hasQueried, setHasQueried] = useState(false);
  const [currentResponse, setCurrentResponse] = useState(''); // Buffer for response tokens
  const [messageBuffer, setMessageBuffer] = useState('');
  const [sessionExpired, setSessionExpired] = useState(false);
  const [feedback, setFeedback] = useState(0);
  const [clientId,setClientId]=useState(()=>uuid())
  const [isNavbarShrunk, setIsNavbarShrunk] = useState(false);

  const { userEmail } = Location.state || {};

  const username = userEmail?.split('@')[0] || 'User';

  useEffect(() => {
    // Example condition when navbar shrinks (adjust as per your logic)
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsNavbarShrunk(true);
      } else {
        setIsNavbarShrunk(false);
      }
    };
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const chatBodyRef = useRef(null);

  useEffect(() => {
    const socket = io('wss://98.83.131.225.nip.io'); // Adjust the URL as necessary

    socket.on('connect', () => {
      console.log('connected');
      socket.emit('register', { clientId });
    });

    socket.on('response', (message) => {
      if (message.clientId === clientId) {
        const words = message.response.split(' ');

        words.forEach((word, index) => {
          setTimeout(() => {
            setMessageBuffer(prevBuffer => {
              const updatedBuffer = prevBuffer + ' ' + word;

              if (updatedBuffer.includes('<|eot_id|>')) {
                const [fullMessage, ...remainingBuffer] = updatedBuffer.split('<|eot_id|>');
                const trimmedMessage = fullMessage.trim();

                setChatHistory(prevHistory => [
                  ...prevHistory,
                  {
                    id: prevHistory.length + 1,
                    message: trimmedMessage,
                    type: 'bot',
                    timestamp: new Date()
                  }
                ]);
                setCurrentResponse('');
                return remainingBuffer.join('<|eot_id|>');
              }

              setCurrentResponse(prevResponse => prevResponse + ' ' + word);

              return updatedBuffer;
            });
          }, index * 5);
        });
      }
    });

    socket.on('disconnect', () => {
      console.log('disconnected');
    });

    return () => {
      socket.disconnect();
    };
  }, [clientId])

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval);
          setSessionExpired(true);
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const cancelTimer = () => setTimer(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentConversations([]);
    }, 86400000);

    return () => clearInterval(interval);
  }, [currentConversations]);

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, feedbacks, currentResponse]);

  const startNewChat = (initialMessage = '') => {
    const trimmedName = newChatName.trim();
    const chatName = trimmedName === '' ? `New Chat ${conversations.length + 1}` : (trimmedName.length > 20 ? trimmedName.substring(0, 20) + '...' : trimmedName);
    const newChat = {
      id: Date.now(),
      name: chatName,
      subheading: initialMessage.substring(0, 20),
      timestamp: new Date(),
      history: []
    };

    setConversations([...conversations, newChat]);
    setCurrentConversations([newChat]);
    setNewChatName('');
    setActiveChat(newChat.id);
    setChatHistory([]);
  };

  const setActiveChatAndLoadHistory = (chatId) => {
    setActiveChat(chatId);
    const chat = conversations.find(c => c.id === chatId);
    if (chat) {
      setChatHistory(chat.history);
      setShowOptions(false);
    }
  };

  const updateHistoryWhileTyping = (inputText) => {
    // Update the chat history with user typing
    const updatedConversations = conversations.map(chat => {
      if (chat.id === activeChat) {
        return { ...chat, subheading: inputText.substring(0, 20) };
      }
      return chat;
    });
    setConversations(updatedConversations);
  };

  const deleteChat = (chatId) => {
    setConversations(conversations.filter(chat => chat.id !== chatId));
    setChatHistory([]);
    setActiveChat(null);
  };

  const editChatName = (chatId) => {
    const newName = prompt('Enter new chat name:');
    if (newName) {
      setConversations(conversations.map(chat =>
        chat.id === chatId ? { ...chat, name: newName } : chat
      ));
    }
  };

  const handleOptionClick = async (option) => {
    setShowOptions(false);
    setActiveChat(null);


    let botResponse;

    switch (option) {
      case 'Medication':
        botResponse = `Hi ${username}, I'm here to help you with all your queries regarding medication!`;
        break;
      case 'Diagnosis':
        botResponse = `Hi ${username}, let's discuss your diagnosis. What would you like to know?`;
        break;
      case 'Lifestyle':
        botResponse = `Hi ${username}, I'm here to guide you on lifestyle choices for better health!`;
        break;
      case 'Others':
        botResponse = `Hi ${username}, how else can I assist you today?`;
        break;
      default:
        botResponse = "This is a hardcoded bot response.";
    }

    const responseMessage = {
      id: chatHistory.length,
      message: botResponse,
      type: 'bot',
      timestamp: new Date()
    };

    if (!activeChat) {
      startNewChat(option);
      setChatHistory([responseMessage]);
    } else {
      const updatedConversations = conversations.map(chat => {
        if (chat.id === activeChat) {
          return { ...chat, history: [...chat.history, responseMessage], subheading: option.substring(0, 20) };
        }
        return chat;
      });
      setConversations(updatedConversations);
    }

    try {
      const payload = { "selectedOption": option,"id":clientId}; //add the name of the chat in it as payload chatName: something
      await axios.post(`${process.env.REACT_APP_BASEURL}/llm/start-session`, payload,
        { withCredentials: true });

    } catch (error) {
      console.error('Error starting LLM Model:', error);
    }
  };

  const sendMessageToBot = async (message) => {
    const userMessage = {
      id: chatHistory.length + 1,
      message,
      type: 'user',
      timestamp: new Date()
    };

    const updatedHistory = [...chatHistory, userMessage];
    setChatHistory(updatedHistory);
    setHasQueried(true);

    if (!activeChat) {
      startNewChat(message);
    } else {
      const updatedConversations = conversations.map(chat => {
        if (chat.id === activeChat) {
          return { ...chat, history: updatedHistory, subheading: message.substring(0, 20) };
        }
        return chat;
      });
      setConversations(updatedConversations);
    }

    try {
      await axios.post(`${process.env.REACT_APP_BASEURL}/llm/get-response`, { query: message,id:clientId  },
        { withCredentials: true }

      );
      // const llmResponseMessage = {
      //   id: chatHistory.length + 2,
      //   message: data.response,
      //   type: 'bot',
      //   timestamp: new Date()
      // };


      // setChatHistory([...updatedHistory, llmResponseMessage]);
    } catch (error) {
      console.error('Error querying LLM model:', error);
    }
  };

  const handleSendMessage = () => {
    if (newChatName.trim() === '') return;
    sendMessageToBot(newChatName);
    setNewChatName('');
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default Enter behavior
      handleSendMessage();
    }
  };
  const handleFeedback = (feedback) => {
    setFeedbacks([...feedbacks, feedback]);
  };


  return (
    <div className="ai-companion-container">
      {/* <NavAIComp
        conversations={conversations}
        setActiveChat={setActiveChatAndLoadHistory}
        deleteChat={deleteChat}
        editChatName={(chatId) => {
          const newName = prompt('Enter new chat name:');
          if (newName) {
            setConversations((prev) =>
              prev.map((chat) => (chat.id === chatId ? { ...chat, name: newName } : chat))
            );
          }
        }}
        activeChat={activeChat}
      /> */}
      <div className="bg-main" style={{ backgroundColor: '#F8F7F7' }}>
        <div className="chat-section" style={{ marginLeft: '219px', height:'100%'}}>
          <div className="top-bars-container" style={{ backgroundColor: 'rgb(248, 247, 247)', padding: '0px 0px 5px' }}>
            <div className="notification-bar">
              <img src={iicon} alt="ibutton" className="icon-info-circle" style={{ marginRight: '10px', width: '20px' }}></img>
              <span style={{ fontSize: '13px' }}>You are on Free trials, this session are valid for -</span>
              <span style={{ marginLeft: '-250px', color: timer <= 300 ? 'red' : 'black' }}>{formatTime(timer)} minutes</span>
              <button className="cancel-btn" onClick={() => setInterval(0)} ><MdCancel /></button>
            </div>
            {/* {sessionExpired && (
          <div className="session-expired-popup">
            <h2>Session Expired</h2>
            <p>Free trial sessions are only valid for 30 minutes. Please log in again.</p>
            <button className="login-button" onClick={() => window.location.href = '/login'}>Login</button>
            <div className="feedback">
              <p>Rate your session:</p>
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  style={{ color: star <= feedback ? 'yellow' : 'gray', cursor: 'pointer' }}
                  onClick={() => handleFeedback(star)}
                >
                  ★
                </span>
              ))}
            </div>
          </div>
        )} */}
            <div className="care-bar">
              <h1 className='Slogan' style={{ marginLeft: '20px', fontWeight: '600' }}>Always There, Always Care.....</h1>
              <p className='Slogan_descp' style={{ marginLeft: '20px', fontSize: '14px', fontWeight: '500' }}> Your personal AI Companion available 24/7 to answer your queries</p>
            </div>
          </div>
          <div className="chat-content-wrapper" ref={chatBodyRef}>
            <div className="chat-header" >
              {showOptions && (
                <div className="header-text" style={{ fontSize: '25px', fontWeight: '400', color: '#5B62FD', marginLeft: '360px' }}>
                  <h1>Hello there! Welcome to PostCare.AI</h1>
                  <p>How may I assist you today?</p>
                </div>
              )}
              {showOptions && (
                <div className="options-blocks">
                  <div className="option-block" onClick={() => handleOptionClick('Medication')}>
                    <div className="option-content">
                      <FaPills className="option-icon" />
                      <div className="option-text">
                        <span className="option-name">Medication</span>
                        <div className="option-description">Ask me about your prescribed medications or old prescriptions</div>
                      </div>
                    </div>
                  </div>

                  <div className="option-block" onClick={() => handleOptionClick('Diagnosis')}>
                    <div className="option-content">
                      <FaDiagnoses className="option-icon" />
                      <div className="option-text">
                        <span className="option-name">Diagnosis</span>
                        <div className="option-description">Ask me about your action plan/diagnosed condition</div>
                      </div>
                    </div>
                  </div>

                  <div className="option-block" onClick={() => handleOptionClick('Lifestyle')}>
                    <div className="option-content">
                      <FaHeartbeat className="option-icon" />
                      <div className="option-text">
                        <span className="option-name">Lifestyle</span>
                        <div className="option-description">Ask me about healthy lifestyle, exercise or customized diet plan</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="chat-body">
              {chatHistory.map((message, index) => (
                <ResponseFormat
                  key={index}
                  message={message}
                  onFeedback={handleFeedback}
                  scrollToBottom={scrollToBottom}
                  type={message.type}
                  timestamp={message.timestamp}
                />
              ))}
              {currentResponse && (
                <ResponseFormat
                  key={chatHistory.length}
                  message={{ id: chatHistory.length + 1, message: currentResponse.trim(), type: 'bot', timestamp: new Date() }}
                  onFeedback={handleFeedback}
                  scrollToBottom={scrollToBottom}
                  type="bot"
                  timestamp={new Date()}
                />
              )}
            </div>
            {/* <div className="feedback-section">
              {feedbacks.map((feedback, index) => (
                <div key={index} className="feedback-item">
                  <p>{feedback}</p>
                </div>
              ))}
            </div> */}
          </div>
          </div>
          <div className="chat-input-container" style={{zIndex:'998'}}>
            <div className="chat-input-section">
              <input
                type="text"
                value={newChatName}
                onChange={(e) => setNewChatName(e.target.value)}
                className="chat-input"
                placeholder="Type a message..."
                onKeyDown={handleKeyPress}
              />
              <button className="send-button" onClick={handleSendMessage}   style={{ backgroundColor: 'blue', color: 'white', border: 'none', padding: '10px', borderRadius: '5px' }}
              >
                <FaPaperPlane />
              </button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default AICompanion;