import React, { } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useNavigate } from 'react-router-dom';
import postcarelogo from '../assets/postcare.png';
import './css/signup.css';

const emailSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
});

const SignUp = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(emailSchema),
  });

  // Function to handle the email verification
  const onSubmit = async (data) => {
    navigate('/signup-verify', { state: { userEmail: data.email } }); // Navigate to the waiting page immediately

    // The email sending process is handled asynchronously but won't block the navigation
    try {
      const response = await fetch(`${process.env.REACT_APP_BASEURL}/auth/sendlink`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: data.email }),
      });

      if (!response.ok) {
        // Handle non-200 status code
        console.error('Failed to send verification email.');
      }
    } 
    catch (error) {
      console.error("Error during email verification:", error);
    }
  };

  return (
    <div className="signup-container">
      <h2 className="signup-title">
        Enter your email to register
      </h2>

      <div className="setup-instruction">
        <p>To continue setting up your PostCare.AI account, please enter your email to generate a one-time </p>
        <p style={{ textAlign: 'center' }}>password valid for 24 hours or access the link to set your password.</p>
      </div>

      <div className="signup-form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="signup-input-group">
            <label htmlFor="email" className="email-label">Email Address  <span style={{ color: 'red' }}>*</span></label>
            <input
              type="text"
              id="email"
              placeholder=" "
              {...register('email')}
              className="signup-input"
            />
            {errors.email && <span className="signup-error">{errors.email.message}</span>}
          </div>

          <button type="submit" className="signup-button">
            Verify Email Address
          </button>

          <p className="help-center">
            For help, contact us through our <a href="/help-center" className="help-center-link">Help Center</a>.
          </p>
        </form>
      </div>

      {/* Bottom logo and terms */}
      <div style={{ position: 'absolute', bottom: '0px', textAlign: 'center', width: '100%' }}>
        <img src={postcarelogo} alt="Postcare Logo" style={{ height: '30px', marginBottom: '10px',marginLeft:'620px' }} />
        <div style={{ fontSize: '0.875rem' }}>
          <a href="/terms-of-use" style={{ color: '#2B60E6', textDecoration: 'none', marginRight: '15px' }}>Terms of Use |</a>
          <a href="/privacy-policy" style={{ color: '#2B60E6', textDecoration: 'none' }}>Privacy Policy</a>
        </div>
      </div>
    </div>
  );
};

export default SignUp;















// import React, { useState } from 'react';
// import { useForm } from 'react-hook-form';
// import { zodResolver } from '@hookform/resolvers/zod';
// import * as z from 'zod';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import postcarelogo from '../assets/postcare.png';
// import { FaEye, FaEyeSlash} from 'react-icons/fa';

// const loginSchema = z.object({
//   email: z.string().email({ message: "Invalid email address" }),
//   password: z.string().min(6, { message: "Password must be at least 6 characters" }),
// });

// const signUpSchema = z.object({
//   name: z.string().min(2, { message: "Name must be at least 2 characters" }),
//   dob: z.string().nonempty({ message: "Date of Birth is required" }),
// });

// const SignUp = () => {
//   const navigate = useNavigate();
//   const [showPassword, setShowPassword] = useState(false);
//   const [step, setStep] = useState(1); // Step 1 for login, Step 2 for additional info
//   const { register, handleSubmit, formState: { errors } } = useForm({
//     resolver: zodResolver(step === 1 ? loginSchema : signUpSchema),
//   });

//   const onSubmit = async (data) => {
//     try {
//       const response = await axios.post('http://localhost:5000/auth', { ...data, step }, {
//         headers: step === 2 ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {}
//       });
//       if (step === 1) {
//         if (response.data.token) {
//           localStorage.setItem('token', response.data.token);
//           setStep(2); // Move to Step 2
//         } else {
//           alert('Login failed. Please try again.');
//         }
//       } else {
//         if (response.data.success) {
//           navigate('/ai-companion'); // Final submission, navigate to AI Companion
//         } else {
//           alert('Profile update failed. Please try again.');
//         }
//       }
//     } catch (error) {
//       alert('An error occurred. Please try again.');
//     }
//   };


//   // Approach 2
//   // const onSubmit = async (data) => {
//   //   if (step === 1) {
//   //     try {
//   //       // Authenticate the user
//   //       const response = await axios.post('http://localhost:5000/auth/register', data);
//   //       if (response.data.token) {
//   //         // Save token or user ID to local storage
//   //         localStorage.setItem('token', response.data.token);
//   //         setStep(2); // Move to Step 2
//   //       } else {
//   //         alert('Registration failed. Please try again.');
//   //       }
//   //     } catch (error) {
//   //       alert('An error occurred during sign up. Please try again.');
//   //     }
//   //   } else {
//   //     try {
//   //       // Store additional details in the backend
//   //       const token = localStorage.getItem('token');
//   //       const config = { headers: { Authorization: `Bearer ${token}` } };
//   //       const response = await axios.post('http://localhost:5000/auth/update-profile', data, config);
//   //       if (response.data.success) {
//   //         navigate('/ai-companion'); // Final submission, navigate to AI Companion
//   //       } else {
//   //         alert('Profile update failed. Please try again.');
//   //       }
//   //     } catch (error) {
//   //       alert('An error occurred during profile update. Please try again.');
//   //     }
//   //   }
//   // };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white' }}>
//       <h2 style={{ textAlign: 'center', marginBottom: '1.5rem', color: '#2B60E6', fontWeight: 'bold', fontSize: '30px' }}>
//         {step === 1 ? "Let's Get Started" : "Almost There"}
//       </h2>
//       <div style={{ 
//         width: '380px', 
//         padding: '2rem', 
//         backgroundColor: 'white', 
//         borderRadius: '10px', 
//         border: '1px solid #ccc',
//         position: 'relative' 
//       }}>
//         <form onSubmit={handleSubmit(onSubmit)}>
//           {step === 1 ? (
//             <>
//               <div style={{ marginBottom: '1rem' }}>
//                 <input
//                   type="text"
//                   placeholder="Phone Number/ Email Address"
//                   {...register('email')}
//                   style={{
//                     width: '100%',
//                     padding: '0.75rem',
//                     borderRadius: '5px',
//                     border: '1px solid #ccc',
//                     fontSize: '1rem',
//                   }}
//                 />
//                 {errors.email && <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.email.message}</span>}
//               </div>
//               <div style={{ marginBottom: '0.5rem', position: 'relative' }}>
//                 <input
//                   type={showPassword ? "text" : "password"}
//                   placeholder="Password"
//                   {...register('password')}
//                   style={{
//                     width: '100%',
//                     padding: '0.75rem',
//                     borderRadius: '5px',
//                     border: '1px solid #ccc',
//                     fontSize: '1rem',
//                   }}
//                 />
//                 <div 
//                   style={{ 
//                     position: 'absolute', 
//                     right: '10px', 
//                     top: '50%', 
//                     transform: 'translateY(-50%)', 
//                     cursor: 'pointer',
//                     color: '#808080', 
//                     width:'15px',
//                     height:'16px'
//                   }}
//                   onClick={togglePasswordVisibility}
//                 >
//                   {showPassword ? <FaEyeSlash size={16} /> : <FaEye size={16} />}
//                 </div>
//                 {errors.password && <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.password.message}</span>}
//               </div>
              
//             </>
//           ) : (
//             <>
//               <div style={{ marginBottom: '1rem' }}>
//                 <input
//                   type="text"
//                   placeholder="Name"
//                   {...register('name')}
//                   style={{
//                     width: '100%',
//                     padding: '0.75rem',
//                     borderRadius: '5px',
//                     border: '1px solid #ccc',
//                     fontSize: '1rem',
//                   }}
//                 />
//                 {errors.name && <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.name.message}</span>}
//               </div>
//               <div style={{ marginBottom: '1rem' }}>
//                 <input
//                   type="date"
//                   placeholder="Date of Birth"
//                   {...register('dob')}
//                   style={{
//                     width: '100%',
//                     padding: '0.75rem',
//                     borderRadius: '5px',
//                     border: '1px solid #ccc',
//                     fontSize: '1rem',
//                   }}
//                 />
//                 {errors.dob && <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.dob.message}</span>}
//               </div>
//             </>
//           )}

//           <button type="submit" style={{
//             width: '100%',
//             padding: '0.75rem',
//             backgroundColor: '#2B60E6',
//             color: 'white',
//             border: 'none',
//             borderRadius: '5px',
//             fontSize: '1rem',
//             fontWeight: 'bold',
//             cursor: 'pointer',
//           }}>
//             {step === 1 ? "Continue" : "Start"}
//           </button>
//         </form>
//         <div style={{ position: 'absolute', bottom: '-120px', width: '100%', textAlign: 'center' }}>
//           <img src={postcarelogo} alt="Postcare Logo" style={{ height: '30px', marginLeft:'90px'}} />
//           <div style={{ marginTop: '10px', fontSize: '0.875rem', marginLeft:'-50px'}}>
//             <a href="/terms-of-use" style={{ color: '#2B60E6', textDecoration: 'none', marginRight: '15px' }}>Terms of Use |</a>
//             <a href="/privacy-policy" style={{ color: '#2B60E6', textDecoration: 'none' }}>Privacy Policy</a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SignUp;
