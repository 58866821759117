import React, { useState, useEffect } from 'react';
import botLogo from '../../assets/botLogo.png'; // Ensure you have the Meta logo image file in your project
import userIcon from '../../assets/userIcon.jpg'; // Ensure you have a user icon image file in your project
import { FaRegThumbsUp, FaRegThumbsDown,  } from 'react-icons/fa';
import { AiOutlineClose,  } from 'react-icons/ai';
import './responseformat.css';

const ResponseFormat = ({ message, index, onFeedback, scrollToBottom, scrollToUp, isLastMessage, }) => {
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  // const [showCareTeamPopup, setShowCareTeamPopup] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState('');
  // const [selectedCareTeamMember, setSelectedCareTeamMember] = useState('');
  // const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // const [careConnectActive, setCareConnectActive] = useState(false);

  useEffect(() => {
    if (showFeedbackPopup ) {
        if (isLastMessage) {
      scrollToBottom();
    } else {
        scrollToUp();
    }
}
  }, [showFeedbackPopup, isLastMessage, scrollToUp, scrollToBottom]);

  const handleLike = () => {
    setLike(!like);
    if (dislike) setDislike(false); // If dislike was selected, deselect it
  };

  const handleDislike = () => {
    setDislike(!dislike);
    setShowFeedbackPopup(!showFeedbackPopup);
    if (like) setLike(false); // If like was selected, deselect it
  };

  const handleFeedbackSubmit = () => {
    if (selectedFeedback) {
      onFeedback(selectedFeedback);
      setShowFeedbackPopup(false);
      setSelectedFeedback('');
    }
  };
  // const isUser = index % 2 === 0; // Even index is user, odd is bot


  // const handleConnect = () => {
  //   setShowCareTeamPopup(true);
  // };

  // const handleSendCareTeam = () => {
  //   setShowCareTeamPopup(false);
  //   setShowSuccessMessage(true);
  //   setCareConnectActive(true); // Activate the care connect icon
  //   setTimeout(() => {
  //     setShowSuccessMessage(false);
  //   }, 4000); // Hides the success message after 4 seconds
  // };

  // const handleUndoSendCareTeam = () => {
  //   setShowSuccessMessage(false);
  //   setCareConnectActive(false); // Deactivate the care connect icon
  // };

  return (
    <div className="chat-wrapper">
      {/* {message.map((message, index) => ( */}
      <div className={`chat-message ${message.type}`}>
        {message.type === 'bot' && (
          <div className="message-row.bot" style={{marginLeft:'40px'}}>
            <div className="messagebot-icon-container ">
              <img src={botLogo} alt="Bot Logo" className="message-icon" style={{width: '50px', height: '50px'}} />
            </div>
            <div className="message-container bot" style={{marginTop:'-45px'}}>
            <div className="messagebot-text">
              {message.message}
            </div>
          </div>
          </div>
        )}
        {message.type === 'user' && (
          <div className="message-row.user">
            <div className="message-container user" style={{marginLeft:'620px'}}>
            <div className="messageuser-text">
              {message.message}
            </div>
            </div>
            <div className="messageuser-icon-container" >
             <img src={userIcon} alt="User Icon" className="message-icon user-icon"style={{width: '40px', height: '40px', marginLeft:'8px'}}/>
          </div>
          </div>
        )}
      </div>
      {/* ))} */}
      {message.type === 'bot' && (
        <div className="feedback-icons">
          <FaRegThumbsUp className={`icon ${like ? 'active' : ''}`} onClick={handleLike} />
          <FaRegThumbsDown className={`icon ${dislike ? 'active' : ''}`} onClick={handleDislike} />
          {/* <FaRegUser className={`icon ${careConnectActive ? 'active' : ''}`} onClick={handleConnect} /> */}
        </div>
      )}
      {showFeedbackPopup && (
        <div className="feedback-popup">
          <AiOutlineClose className="close-icon" onClick={() => setShowFeedbackPopup(false)} />
          <h3>Provide your feedback</h3>
          <select
            value={selectedFeedback}
            onChange={(e) => setSelectedFeedback(e.target.value)}
          >
            <option value="">Select feedback</option>
            <option value="Not Relevant">Not Relevant</option>
            <option value="Incorrect Information">Incorrect Information</option>
            <option value="Too Complex">Too Complex</option>
            <option value="Other">Other</option>
          </select>
          <button onClick={handleFeedbackSubmit}>Submit</button>
        </div>
      )}
      {/* {showCareTeamPopup && (
        <div className="care-team-popup">
          <AiOutlineClose className="close-icon" onClick={() => setShowCareTeamPopup(false)} />
          <h3>Send a message to your Care Team!</h3>
          <p>As per terms and conditions your care team member might take some time to respond back to your message. For more information check your message inbox.</p>
          <div className="care-team-options">
            <div
              className={`care-team-option ${selectedCareTeamMember === 'Elon Musk - Helios Heart Care' ? 'selected' : ''}`}
              onClick={() => setSelectedCareTeamMember('Elon Musk - Helios Heart Care')}
            >
              <div className="image-text-container">
                <img src={userIcon} alt="User Icon" className="option-icon" />
                <span>Elon Musk - Helios Heart Care</span>
              </div>
            </div>
            <div
              className={`care-team-option ${selectedCareTeamMember === 'Jeff Bezos - Melinda Asthma Care' ? 'selected' : ''}`}
              onClick={() => setSelectedCareTeamMember('Jeff Bezos - Melinda Asthma Care')}
            >
              <div className="image-text-container">
                <img src={userIcon} alt="User Icon" className="option-icon" />
                <span>Jeff Bezos - Melinda Asthma Care</span>
              </div>
            </div>
            <div
              className={`care-team-option ${selectedCareTeamMember === 'Warren Buffet - Linda Orthopedics' ? 'selected' : ''}`}
              onClick={() => setSelectedCareTeamMember('Warren Buffet - Linda Orthopedics')}
            >
              <div className="image-text-container">
                <img src={userIcon} alt="User Icon" className="option-icon" />
                <span>Warren Buffet - Linda Orthopedics</span>
              </div>
            </div>
          </div>
          <div className="care-team-buttons">
            <button className="cancel-button" onClick={() => setShowCareTeamPopup(false)}>Cancel</button>
            <button className="send-button" onClick={handleSendCareTeam}>Send</button>
          </div>
        </div>
      )}
      {showSuccessMessage && (
        <div className="success-popup">
          <AiOutlineCheck className="success-icon" />
          <span>Successfully sent to your Care Team!</span>
          <button className="undo-button" onClick={handleUndoSendCareTeam}>Undo</button>
        </div>
      )} */}
    </div>
  );
};

export default ResponseFormat;


// // --------------------------prev code
// import React, { useState, useEffect } from 'react';
// import metaLogo from '../../assets/metaLogo.png'; // Ensure you have the Meta logo image file in your project
// import userIcon from '../../assets/userIcon.png'; // Ensure you have a user icon image file in your project
// import { FaRegThumbsUp, FaRegThumbsDown, FaRegUser } from 'react-icons/fa';
// import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
// import './responseformat.css';

// const ResponseFormat = ({ message, onFeedback, scrollToBottom, scrollToUp, isLastMessage}) => {
//   const [like, setLike] = useState(false);
//   const [dislike, setDislike] = useState(false);
//   const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
//   const [showCareTeamPopup, setShowCareTeamPopup] = useState(false);
//   const [selectedFeedback, setSelectedFeedback] = useState('');
//   const [selectedCareTeamMember, setSelectedCareTeamMember] = useState('');
//   const [showSuccessMessage, setShowSuccessMessage] = useState(false);
//   const [careConnectActive, setCareConnectActive] = useState(false);

//   useEffect(() => {
//     if (showFeedbackPopup || showCareTeamPopup || showSuccessMessage) {
//         if (isLastMessage) {
//       scrollToBottom();
//     } else {
//         scrollToUp();
//     }
// }
//   }, [showFeedbackPopup, showCareTeamPopup, showSuccessMessage, isLastMessage, scrollToUp, scrollToBottom]);

//   const handleLike = () => {
//     setLike(!like);
//     if (dislike) setDislike(false); // If dislike was selected, deselect it
//   };

//   const handleDislike = () => {
//     setDislike(!dislike);
//     setShowFeedbackPopup(!showFeedbackPopup);
//     if (like) setLike(false); // If like was selected, deselect it
//   };

//   const handleFeedbackSubmit = () => {
//     if (selectedFeedback) {
//       onFeedback(selectedFeedback);
//       setShowFeedbackPopup(false);
//       setSelectedFeedback('');
//     }
//   };

//   const handleConnect = () => {
//     setShowCareTeamPopup(true);
//   };

//   const handleSendCareTeam = () => {
//     setShowCareTeamPopup(false);
//     setShowSuccessMessage(true);
//     setCareConnectActive(true); // Activate the care connect icon
//     setTimeout(() => {
//       setShowSuccessMessage(false);
//     }, 4000); // Hides the success message after 4 seconds
//   };

//   const handleUndoSendCareTeam = () => {
//     setShowSuccessMessage(false);
//     setCareConnectActive(false); // Deactivate the care connect icon
//   };

//   return (
//     <div>
//       <div className={`chat-message ${message.type}`}>
//         {message.type === 'bot' && (
//           <div className="message-container bot">
//             <div className="messagebot-icon-container bot-icon-container">
//               <img src={metaLogo} alt="Meta Logo" className="message-icon" />
//             </div>
//             <div className="messagebot-text">
//               {message.message}
//             </div>
//           </div>
//         )}
//         {message.type === 'user' && (
//           <div className="message-container user">
//             <div className="messageuser-text">
//               {message.message}
//             </div>
//             <img src={userIcon} alt="User Icon" className="messageuser-icon user-icon" />
//           </div>
//         )}
//       </div>
//       {message.type === 'bot' && (
//         <div className="feedback-icons">
//           <FaRegThumbsUp className={`icon ${like ? 'active' : ''}`} onClick={handleLike} />
//           <FaRegThumbsDown className={`icon ${dislike ? 'active' : ''}`} onClick={handleDislike} />
//           <FaRegUser className={`icon ${careConnectActive ? 'active' : ''}`} onClick={handleConnect} />
//         </div>
//       )}
//       {showFeedbackPopup && (
//         <div className="feedback-popup">
//           <AiOutlineClose className="close-icon" onClick={() => setShowFeedbackPopup(false)} />
//           <h3>Provide your feedback</h3>
//           <select
//             value={selectedFeedback}
//             onChange={(e) => setSelectedFeedback(e.target.value)}
//           >
//             <option value="">Select feedback</option>
//             <option value="Not Relevant">Not Relevant</option>
//             <option value="Incorrect Information">Incorrect Information</option>
//             <option value="Too Complex">Too Complex</option>
//             <option value="Other">Other</option>
//           </select>
//           <button onClick={handleFeedbackSubmit}>Submit</button>
//         </div>
//       )}
//       {showCareTeamPopup && (
//         <div className="care-team-popup">
//           <AiOutlineClose className="close-icon" onClick={() => setShowCareTeamPopup(false)} />
//           <h3>Send a message to your Care Team!</h3>
//           <p>As per terms and conditions your care team member might take some time to respond back to your message. For more information check your message inbox.</p>
//           <div className="care-team-options">
//             <div
//               className={`care-team-option ${selectedCareTeamMember === 'Elon Musk - Helios Heart Care' ? 'selected' : ''}`}
//               onClick={() => setSelectedCareTeamMember('Elon Musk - Helios Heart Care')}
//             >
//               <div className="image-text-container">
//                 <img src={userIcon} alt="User Icon" className="option-icon" />
//                 <span>Elon Musk - Helios Heart Care</span>
//               </div>
//             </div>
//             <div
//               className={`care-team-option ${selectedCareTeamMember === 'Jeff Bezos - Melinda Asthma Care' ? 'selected' : ''}`}
//               onClick={() => setSelectedCareTeamMember('Jeff Bezos - Melinda Asthma Care')}
//             >
//               <div className="image-text-container">
//                 <img src={userIcon} alt="User Icon" className="option-icon" />
//                 <span>Jeff Bezos - Melinda Asthma Care</span>
//               </div>
//             </div>
//             <div
//               className={`care-team-option ${selectedCareTeamMember === 'Warren Buffet - Linda Orthopedics' ? 'selected' : ''}`}
//               onClick={() => setSelectedCareTeamMember('Warren Buffet - Linda Orthopedics')}
//             >
//               <div className="image-text-container">
//                 <img src={userIcon} alt="User Icon" className="option-icon" />
//                 <span>Warren Buffet - Linda Orthopedics</span>
//               </div>
//             </div>
//           </div>
//           <div className="care-team-buttons">
//             <button className="cancel-button" onClick={() => setShowCareTeamPopup(false)}>Cancel</button>
//             <button className="send-button" onClick={handleSendCareTeam}>Send</button>
//           </div>
//         </div>
//       )}
//       {showSuccessMessage && (
//         <div className="success-popup">
//           <AiOutlineCheck className="success-icon" />
//           <span>Successfully sent to your Care Team!</span>
//           <button className="undo-button" onClick={handleUndoSendCareTeam}>Undo</button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ResponseFormat;