import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash, FaCheckCircle } from 'react-icons/fa';
import postcarelogo from '../assets/postcare.png';

const SetNewPassword = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({ newPassword: false, confirmPassword: false });
  const [passwordRules, setPasswordRules] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const newPassword = watch('newPassword');
  const confirmPassword = watch('confirmPassword');

  useEffect(() => {
    // Validate password rules
    setPasswordRules({
      length: newPassword?.length >= 8,
      uppercase: /[A-Z]/.test(newPassword),
      lowercase: /[a-z]/.test(newPassword),
      number: /\d/.test(newPassword),
      specialChar: /[!@#$%^&*()?"{}|<>]/.test(newPassword),
    });

    // Check if passwords match
    setPasswordsMatch(newPassword === confirmPassword);

  }, [newPassword, confirmPassword]);

  const onSubmit = async (data) => {
    if (!passwordsMatch) {
      alert('Passwords do not match.');
      return;
    }

    const payload = { newPass: data.newPassword };

    try {
      const response = await axios.patch(`${process.env.REACT_APP_BASEURL}/auth/changepass`, payload, {
        withCredentials: true
      })
      if (response.status === 200) {
        setSuccessPopup(true);
        setTimeout(() => {
          navigate('/login');
        }, 900000); // Redirect to login after 5 minutes if no action is taken
      } else {
        alert('Failed to reset password. Please try again.');
      }
    } catch (error) {
      alert('An error occurred during the password reset. Please try again.');
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white', marginTop:'-40px' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '1.5rem', color: '#2B60E6', fontWeight: 'bold', fontSize: '30px' }}>Set New Password</h2>

      <div style={{
        width: '380px',
        padding: '2rem',
        backgroundColor: 'white',
        borderRadius: '10px',
        border: '1px solid #ccc',
        position: 'relative'
      }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* New Password Field */}
          <div style={{ marginBottom: '1rem', position: 'relative' }}>
            <input
              type={showPassword.newPassword ? 'text' : 'password'}
              placeholder="New Password"
              {...register('newPassword', { required: 'Password is required', minLength: { value: 8, message: 'Password must be at least 8 characters' } })}
              style={{
                width: '100%',
                padding: '0.75rem',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontSize: '1rem',
              }}
            />
            <div
              style={{ position: 'absolute', right: '9px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
              onClick={() => togglePasswordVisibility('newPassword')}
            >
              {showPassword.newPassword ? <FaEyeSlash size={16} /> : <FaEye size={16} />}
            </div>
          </div>

          {/* Confirm Password Field */}
          <div style={{ marginBottom: '1rem', position: 'relative' }}>
            <input
              type={showPassword.confirmPassword ? 'text' : 'password'}
              placeholder="Confirm New Password"
              {...register('confirmPassword', { required: 'Confirmation is required' })}
              style={{
                width: '100%',
                padding: '0.75rem',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontSize: '1rem',
              }}
            />
            {/* Password Rules */}
          {/* Password Rules */}
<p className='titlepass' style={{color:'black', marginTop:'5px'}}>Password must have<span style={{ color: 'red' }}>*</span> :</p>
<ul style={{ listStyle: 'none', paddingLeft: '0', color: '#505050', fontSize:'14px' }}>
  <li style={{ marginBottom: '0.5rem', display: 'flex', alignItems: 'center' }}>
    <FaCheckCircle color={passwordRules.length ? 'green' : 'grey'} style={{ marginRight: '8px' }} /> 
    <span>8 characters minimum</span>
  </li>
  <li style={{ marginBottom: '0.5rem', display: 'flex', alignItems: 'center' }}>
    <FaCheckCircle color={passwordRules.uppercase ? 'green' : 'grey'} style={{ marginRight: '8px' }} /> 
    <span>One uppercase letter</span>
  </li>
  <li style={{ marginBottom: '0.5rem', display: 'flex', alignItems: 'center' }}>
    <FaCheckCircle color={passwordRules.lowercase ? 'green' : 'grey'} style={{ marginRight: '8px' }} /> 
    <span>One lowercase letter</span>
  </li>
  <li style={{ marginBottom: '0.5rem', display: 'flex', alignItems: 'center' }}>
    <FaCheckCircle color={passwordRules.number ? 'green' : 'grey'} style={{ marginRight: '8px' }} /> 
    <span>One number</span>
  </li>
  <li style={{ display: 'flex', alignItems: 'center' }}>
    <FaCheckCircle color={passwordRules.specialChar ? 'green' : 'grey'} style={{ marginRight: '8px' }} /> 
    <span>One special character-'!@#$%^&*()'</span>
  </li>
</ul>

            <div
              style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-580%)', cursor: 'pointer' }}
              onClick={() => togglePasswordVisibility('confirmPassword')}
            >
              {showPassword.confirmPassword ? <FaEyeSlash size={16} /> : <FaEye size={16} />}
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={!(passwordRules.length && passwordRules.uppercase && passwordRules.lowercase && passwordRules.number && passwordRules.specialChar && passwordsMatch)}
            style={{
              width: '100%',
              padding: '0.75rem',
              backgroundColor: '#2B60E6',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              fontSize: '1rem',
              fontWeight: 'bold',
              cursor: 'pointer',
              opacity: !(passwordRules.length && passwordRules.uppercase && passwordRules.lowercase && passwordRules.number && passwordRules.specialChar && passwordsMatch) ? '0.6' : '1',
            }}
          >
            Set Password
          </button>
        </form>

        <div style={{ position: 'absolute', bottom: '-100px', width: '100%', textAlign: 'center' }}>
          <img src={postcarelogo} alt="Postcare Logo" style={{ height: '30px', marginLeft: '90px' }} />
          <div style={{ marginTop: '10px', fontSize: '0.875rem', marginLeft: '-50px' }}>
            <a href="/terms-of-use" style={{ color: '#2B60E6', textDecoration: 'none', marginRight: '15px' }}>Terms of Use |</a>
            <a href="/privacy-policy" style={{ color: '#2B60E6', textDecoration: 'none' }}>Privacy Policy</a>
          </div>
        </div>
      </div>

      {/* Success Popup */}
      {successPopup && (
        <div className='wrap'style={{marginTop:'200px'}}>
        {/* <div style={{
          position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',  // dim effect
          // backdropFilter: 'blur(5px)', // optional blur effect
          zIndex: 1 // make sure this is behind the popup but above the rest of the content
        }}></div> */}
        <div style={{
          position: 'fixed', top: '44%', left: '50%', transform: 'translate(-50%, -60%)',
          backgroundColor: 'white', padding: '2rem', borderRadius: '10px', textAlign: 'center',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1) ', height:'81%', marginTop:'50px'
        }}>
          <FaCheckCircle color="green" size={40} style={{ marginBottom: '1rem' , marginLeft:'210px',marginTop:'70px'}} />
          <h3>Welcome to Postcare.AI</h3>
          <p>Your password has been successfully saved and registered.</p>
          <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'center', gap: '1rem' }}>
            <button onClick={() => navigate('/login')} style={{
              padding: '0.75rem 1.5rem',
              backgroundColor: '#2B60E6',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}>Login</button>
            <button onClick={() => navigate('/ai-companion')} style={{
              padding: '0.75rem 1.5rem',
              backgroundColor: '#2B60E6',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}>Start</button>
          </div>
        </div>
    </div>
      )
}</div>
  );
};

export default SetNewPassword;
